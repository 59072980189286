import React from "react";
import styled from "styled-components";
import { bgColor } from "../theme/colors";
import Navbar from "../component/layouts/Navbar";
import FooterCard from "../component/layouts/Footer";
import CommunityCard from "../component/CommunityCard";
import { Heading6, Paragraph2 } from "../theme/typography";
import { useMode } from "../store/mode/hooks";
import { MODE } from "../store/mode";
import { BREAKPOINTS } from "../theme/breakpoints";
import { Helmet } from "react-helmet-async";

const Header = styled.header`
  width: 100%;
  /* height: 130px; */
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  z-index: 999;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 10px 0;
  }
`;
const MainDiv = styled.div<{ mode: MODE }>`
  width: 100vw;
  height: auto;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: absolute;
  top: 0;
`;
const ImageDiv = styled.div`
  width: 100%;
  height: 285px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 227px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 170px;
  }
`;
// const AboutUsImage = styled.img`
//   width: 100%;
//   height: 285px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   @media (max-width: ${BREAKPOINTS.md}px) {
//     height: 227px;
//   }
//   @media (max-width: ${BREAKPOINTS.sm}px) {
//     height: 170px;
//   }
// `;
const OverContentBox = styled.div<{ mode: MODE }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  background: var(--Primary-main, #03c988);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  padding-top: 80px;
  /* text */
  color: ${(props) =>
    props.mode === MODE.DARK ? "rgba(0, 0, 0, 0.87)" : "#FAFAFA"};
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 72px */
  letter-spacing: -0.5px;
  border-radius: 0px 0px 8px 8px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 55px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 48px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 40px;
  }
`;
const ContentDiv = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 40px;
  }
`;
const ContentBox = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
    flex-direction: column;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CommunityBox = styled.div<{ mode: MODE }>`
  border-radius: 0px 0px 40px 40px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 99;
`;

const BelowFooterDiv = styled.div`
  background: #424242;
`;
const FooterDiv = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  top: -40px;
  background: #424242;
  display: flex;
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    height: 300px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 430px;
  }
  @media (max-width: 600px) {
    height: 600px;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
  }
`;
const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
`;
const PrivacyPolicy = () => {
  const mode = useMode();
  return (
    <MainDiv mode={mode}>
      <Helmet>
        <title>Policy - Buckmint</title>
      </Helmet>
      <Header>
        <Navbar />
      </Header>
      <ImageDiv>
        {/* <AboutUsImage src={blogImage} alt="" /> */}
        <OverContentBox mode={mode}>
          <p>Privacy Policy</p>
        </OverContentBox>
      </ImageDiv>

      <ContentDiv mode={mode}>
        <ContentBox>
          <CustomParagraph2 mode={mode}>
            At Buckmint, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, disclose, and protect your
            information when you use our website.
          </CustomParagraph2>
          <TextDiv>
            <CustomHeading6 mode={mode}>
              Information Collection and Use
            </CustomHeading6>
            <CustomParagraph2 mode={mode}>
              We may collect personal information from you when you visit our
              website, register an account, place an order, subscribe to our
              newsletter, respond to a survey, or fill out a form. This
              information may include your name, email address, mailing address,
              phone number, and payment details.
              <br />
              We use this information for purposes such as processing
              transactions, providing customer service, sending newsletters and
              promotional emails, improving our website and services, and
              complying with legal obligations.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>Cookies</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              We use cookies to enhance your experience on our website. Cookies
              are small files stored on your computer's hard drive that help us
              analyze web traffic, customize content, and improve user
              experience. You can choose to accept or decline cookies through
              your browser settings, but this may affect your ability to access
              certain features of the website.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>Third-Party Disclosure</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              We do not sell, trade, or otherwise transfer your personal
              information to third parties without your consent. However, we may
              share your information with trusted third parties who assist us in
              operating our website, conducting business, or servicing you, as
              long as those parties agree to keep this information confidential.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>Data Security</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              We implement a variety of security measures to maintain the safety
              of your personal information when you place an order or enter,
              submit, or access your information. We use encryption technology
              to protect sensitive information transmitted online, and we
              restrict access to your personal information to only those
              employees who need it to perform a specific job.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>Links to Other Websites</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              Our website may contain links to third-party websites for your
              convenience. However, we are not responsible for the privacy
              practices or content of those websites. We encourage you to review
              the privacy policies of any third-party sites you visit.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>
              Changes to This Privacy Policy
            </CustomHeading6>
            <CustomParagraph2 mode={mode}>
              We reserve the right to update or change this Privacy Policy at
              any time. Any changes will be effective immediately upon posting
              on this page. We encourage you to check this page periodically for
              updates.
            </CustomParagraph2>
          </TextDiv>
        </ContentBox>
      </ContentDiv>

      <CommunityBox mode={mode}>
        <CommunityCard />
      </CommunityBox>

      <BelowFooterDiv>
        <FooterDiv>
          <FooterCard />
        </FooterDiv>
      </BelowFooterDiv>
    </MainDiv>
  );
};

export default PrivacyPolicy;
