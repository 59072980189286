import React, { useEffect } from "react";
import "./App.css";
import Home from "./pages/Home";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Blogs from "./pages/Blogs";
import Faqs from "./pages/Faqs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsandCondition from "./pages/TermsAndCondition";
import DetailBlog from "./pages/DetailBlogPage/DetailBlog";
import { useSystempreferenceMode } from "./store/mode/hooks";
import ReactGA from "react-ga4";
import Breadcrumbs from "./component/Breadcrumbs/Breadcrumbs";

ReactGA.initialize("G-W5B27WS3SE");

function App() {
  const location = useLocation();
  useSystempreferenceMode();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <div>
      <Breadcrumbs />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/blog" element={<Navigate to="/blog/page/1" />} />
        <Route path="/blog/page/:page" element={<Blogs />} />
        <Route path="/detail/:permalink" element={<DetailBlog />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandcondition" element={<TermsandCondition />} />
      </Routes>
    </div>
  );
}

export default App;
