import React, { useState } from "react";
import styled from "styled-components";
import { bgColor } from "../theme/colors";
import Navbar from "../component/layouts/Navbar";
import FooterCard from "../component/layouts/Footer";
import CommunityCard from "../component/CommunityCard";
import BlogCard from "../component/BlogPageComponent/BlogCard";
import CategoriesCard from "../component/CategoriesCard";
import BlogContainerCard from "../component/BlogPageComponent/BlogContainerCard";
import { MODE } from "../store/mode";
import { useMode } from "../store/mode/hooks";
import { BREAKPOINTS } from "../theme/breakpoints";
import BlogSearchBox from "../component/BlogPageComponent/BlogSearchBox";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Header = styled.header`
  width: 100%;
  /* height: 130px; */
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  z-index: 999;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 10px 0;
  }
`;
const MainDiv = styled.div<{ mode: MODE }>`
  width: 100vw;
  height: auto;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: absolute;
  top: 0;
`;
const ImageDiv = styled.div`
  width: 100%;
  height: 285px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 227px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 170px;
  }
`;
// const AboutUsImage = styled.img`
//   width: 100%;
//   height: 285px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   @media (max-width: ${BREAKPOINTS.md}px){
//   height: 227px;
// }
// `;
const OverContentBox = styled.div<{ mode: MODE }>`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0px 0px 8px 8px;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  background: var(--Primary-main, #03c988);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  padding-top: 80px;
  /* text */
  color: ${(props) =>
    props.mode === MODE.DARK ? "rgba(0, 0, 0, 0.87)" : "#FAFAFA"};
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 72px */
  letter-spacing: -0.5px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 55px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 48px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 40px;
  }
`;

const BlogDiv = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
const CategoriesBox = styled.div<{ mode: MODE }>`
  width: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 40px;
  }
`;
const BlogSearchDiv = styled.div<{ mode: MODE }>`
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 40px;
  }
`;
const BlogContainer = styled.div<{ mode: MODE }>`
  width: 100%;
  padding-top: 44px;
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-top: 20px;
  }
`;

const CommunityBox = styled.div<{ mode: MODE }>`
  border-radius: 0px 0px 40px 40px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 99;
`;

const BelowFooterDiv = styled.div`
  background: #424242;
`;
const FooterDiv = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  top: -40px;
  background: #424242;
  display: flex;
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    height: 300px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 430px;
  }
  @media (max-width: 600px) {
    height: 600px;
  }
`;

const Blogs = () => {
  const { page } = useParams();
  const currentPage = page || 1;
  const canonicalUrl = `https://buckmint.org/blog/page/${currentPage}`;

  const mode = useMode();
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");

  return (
    <MainDiv mode={mode}>
      <Helmet>
        <title>Blog Page {currentPage} - Buckmint</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          name="description"
          content={`Page ${currentPage} of our latest blog updates`}
        />
      </Helmet>
      <Header>
        <Navbar />
      </Header>
      <ImageDiv>
        {/* <AboutUsImage src={blogImage} alt="" /> */}
        <OverContentBox mode={mode}>
          <p>Blogs</p>
        </OverContentBox>
      </ImageDiv>
      <BlogDiv mode={mode}>
        <BlogCard />
      </BlogDiv>
      <CategoriesBox mode={mode}>
        <CategoriesCard
          selectedList={selectedList}
          setSelectedList={setSelectedList}
        />
      </CategoriesBox>
      <BlogSearchDiv mode={mode}>
        <BlogSearchBox setSearch={setSearch} />
      </BlogSearchDiv>
      <BlogContainer mode={mode}>
        <BlogContainerCard search={search} selectedList={selectedList} />
      </BlogContainer>
      <CommunityBox mode={mode}>
        <CommunityCard />
      </CommunityBox>
      <BelowFooterDiv>
        <FooterDiv>
          <FooterCard />
        </FooterDiv>
      </BelowFooterDiv>
    </MainDiv>
  );
};

export default Blogs;
