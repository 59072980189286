import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Navbar from "../component/layouts/Navbar";
import { bgColor } from "../theme/colors";
import Hero from "../component/Hero";
import InfoCard from "../component/InfoCard";
import ClimateFocusedCard from "../component/ClimateFocusedCard";
import SelfCustodyCard from "../component/SelfCustodyCard";
import GaslessTradingCard from "../component/GaslessTradingCard";
import TraditionalOrderbookCard from "../component/TraditionalOrderbookCard";
import OurPlatformCard from "../component/OurPlatformCard";
import BackedByCard from "../component/BackedByCard";
import BlogCard from "../component/BlogCard";
// import Faqcard from "../component/FaqCard";
import CommunityCard from "../component/CommunityCard";
import FooterCard from "../component/layouts/Footer";
import AnnouncementCard from "../component/AnnouncementCard";
import { useMode } from "../store/mode/hooks";
import { MODE } from "../store/mode";
import { BREAKPOINTS } from "../theme/breakpoints";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet-async";

const MainDiv = styled.div<{ mode: MODE }>`
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
const TopToBottomAnimation = keyframes`
0%{ opacity:0; transform:translateY(-200px)}
80% {opacity:1; transform:translateY(10px)}
100% {opacity:1; transform:translateY(0px)}
`;

const Header = styled.header<{ showAnnoucement: boolean }>`
  width: 100%;
  display: flex;
  padding: ${(props) => (props.showAnnoucement ? "45px 0px" : "20px 0")};
  align-items: center;
  flex-direction: column;
  gap: 12px;
  position: relative;
  z-index: 9999;
  animation: ${TopToBottomAnimation};
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: ${(props) => (props.showAnnoucement ? "35px 0px" : "10px 0")};
  }
`;
const AnnouncementBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99999;
  animation: ${TopToBottomAnimation};
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
`;
const HeroSection = styled.div<{ mode: MODE }>`
  padding-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
const InfoBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-top: 40px;
  }
`;
const ClimateFocusedBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
const SelfCustodyBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
const GaslessTradingBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
const TraditionalOrderbookBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
const OurPlatformBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 60px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-top: 40px;
  }
`;
const BackedbyBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-top: 20px;
  }
`;
const BlogBox = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
`;
// const FaqBox = styled.div<{ mode: MODE }>`
//   width: 100%;
//   padding-top: 100px;
//   display: flex;
//   justify-content: center;
//   background-color: ${(props) =>
//     props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
//   position: relative;
//   z-index: 99;
// `;
const CommunityBox = styled.div<{ mode: MODE }>`
  border-radius: 0px 0px 40px 40px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 99;
`;
const BelowFooterDiv = styled.div`
  background: #424242;
`;
const FooterDiv = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  top: -40px;
  background: #424242;
  display: flex;
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    height: 300px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 430px;
  }
  @media (max-width: 600px) {
    height: 600px;
  }
`;

const Home = () => {
  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);
  const mode = useMode();

  return (
    <MainDiv mode={mode}>
      <Helmet>
        <title>Buckmint</title>
        <link rel="canonical" href="https://buckmint.org" />
      </Helmet>
      <Toaster />
      {showAnnouncement && (
        <AnnouncementBox mode={mode}>
          <AnnouncementCard setShowAnnouncement={setShowAnnouncement} />
        </AnnouncementBox>
      )}
      <Header showAnnoucement={showAnnouncement}>
        <Navbar />
      </Header>
      <HeroSection mode={mode}>
        <Hero />
      </HeroSection>
      <InfoBox mode={mode}>
        <InfoCard />
      </InfoBox>
      <ClimateFocusedBox mode={mode}>
        <ClimateFocusedCard />
      </ClimateFocusedBox>
      <SelfCustodyBox mode={mode}>
        <SelfCustodyCard />
      </SelfCustodyBox>
      <GaslessTradingBox mode={mode}>
        <GaslessTradingCard />
      </GaslessTradingBox>
      <TraditionalOrderbookBox mode={mode}>
        <TraditionalOrderbookCard />
      </TraditionalOrderbookBox>
      <OurPlatformBox mode={mode}>
        <OurPlatformCard />
      </OurPlatformBox>
      <BackedbyBox mode={mode}>
        <BackedByCard />
      </BackedbyBox>
      <BlogBox mode={mode}>
        <BlogCard />
      </BlogBox>
      {/* <FaqBox mode={mode}>
        <Faqcard />
      </FaqBox> */}
      <CommunityBox mode={mode}>
        <CommunityCard />
      </CommunityBox>
      <BelowFooterDiv>
        <FooterDiv>
          <FooterCard />
        </FooterDiv>
      </BelowFooterDiv>
    </MainDiv>
  );
};

export default Home;
