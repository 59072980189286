import React from "react";
import styled from "styled-components";
import { bgColor } from "../theme/colors";
import Navbar from "../component/layouts/Navbar";
import FooterCard from "../component/layouts/Footer";
import CommunityCard from "../component/CommunityCard";
import { Heading6, Paragraph2 } from "../theme/typography";
import { useMode } from "../store/mode/hooks";
import { MODE } from "../store/mode";
import { BREAKPOINTS } from "../theme/breakpoints";
import { Helmet } from "react-helmet-async";

const Header = styled.header`
  width: 100%;
  /* height: 130px; */
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  position: fixed;
  z-index: 999;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 10px 0;
  }
`;
const MainDiv = styled.div<{ mode: MODE }>`
  width: 100vw;
  height: auto;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: absolute;
  top: 0;
`;
const ImageDiv = styled.div`
  width: 100%;
  height: 285px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 227px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 170px;
  }
`;
// const AboutUsImage = styled.img`
//   width: 100%;
//   height: 285px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   @media (max-width: ${BREAKPOINTS.md}px){
//   height: 227px;
// }
// @media (max-width: ${BREAKPOINTS.sm}px) {
//     height: 170px;
//   }
// `;
const OverContentBox = styled.div<{ mode: MODE }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  background: var(--Primary-main, #03c988);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  padding-top: 80px;
  /* text */
  color: ${(props) =>
    props.mode === MODE.DARK ? "rgba(0, 0, 0, 0.87)" : "#FAFAFA"};
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 72px */
  letter-spacing: -0.5px;
  border-radius: 0px 0px 8px 8px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 55px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 44px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 34px;
  }
  @media (max-width: 385px) {
    font-size: 30px;
  }
`;
const ContentDiv = styled.div<{ mode: MODE }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  position: relative;
  z-index: 99;
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 40px;
  }
`;
const ContentBox = styled.div`
  width: 78%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
    flex-direction: column;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CommunityBox = styled.div<{ mode: MODE }>`
  border-radius: 0px 0px 40px 40px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${(props) =>
    props.mode === MODE.DARK ? bgColor.secondary : bgColor.primary};
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 99;
`;

const BelowFooterDiv = styled.div`
  background: #424242;
`;
const FooterDiv = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  top: -40px;
  background: #424242;
  display: flex;
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    height: 300px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 430px;
  }
  @media (max-width: 600px) {
    height: 600px;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.md}px) {
  }
`;
const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.md}px) {
  }
`;

const TermsandCondition = () => {
  const mode = useMode();
  return (
    <MainDiv mode={mode}>
      <Helmet>
        <title>Terms and Conditions - Buckmint</title>
      </Helmet>
      <Header>
        <Navbar />
      </Header>
      <ImageDiv>
        {/* <AboutUsImage src={blogImage} alt="" /> */}
        <OverContentBox mode={mode}>
          <p>Terms and Conditions</p>
        </OverContentBox>
      </ImageDiv>

      <ContentDiv mode={mode}>
        <ContentBox>
          <CustomParagraph2 mode={mode}>
            Welcome to Buckmint! These terms of service outline the rules and
            regulations for the use of our platform.
            <br />
            <br />
            By accessing this website, we assume you accept these terms and
            conditions in full. Do not continue to use Buckmint's website if you
            do not accept all of the terms and conditions stated on this page.
            <br />
            <br />
            The following terminology applies to these Terms and Conditions,
            Privacy Statement, and Disclaimer Notice and any or all Agreements:
            "Client", "You" and "Your" refers to you, the person accessing this
            website and accepting the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves, or either the Client or ourselves. All terms refer to the
            offer, acceptance and consideration of payment necessary to
            undertake the process of our assistance to the Client in the most
            appropriate manner, whether by formal meetings of a fixed duration,
            or any other means, for the express purpose of meeting the Client's
            needs in respect of provision of the Company's stated
            services/products, in accordance with and subject to, prevailing law
            of. Any use of the above terminology or other words in the singular,
            plural, capitalisation and/or he/she or they, are taken as
            interchangeable and therefore as referring to same.
            <br />
            <br />
            <br />
          </CustomParagraph2>
          <TextDiv>
            <CustomHeading6 mode={mode}>Cookies</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              We employ the use of cookies. By using Buckmint's website you
              consent to the use of cookies in accordance with Buckmint's
              privacy policy.
              <br />
              <br />
              Most of the modern-day interactive web sites use cookies to enable
              us to retrieve user details for each visit. Cookies are used in
              some areas of our site to enable the functionality of this area
              and ease of use for those people visiting. Some of our
              affiliate/advertising partners may also use cookies.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>License</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              Unless otherwise stated, Buckmint and/or its licensors own the
              intellectual property rights for all material on Buckmint. All
              intellectual property rights are reserved. You may view and/or
              print pages from https://buckmint.org for your own personal use
              subject to restrictions set in these terms and conditions.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>You must not:</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              Republish material from https://buckmint.org
              <br />
              Sell, rent or sub-license material from https://buckmint.org
              <br />
              Reproduce, duplicate or copy material from https://buckmint.org
              <br />
              Redistribute content from Buckmint (unless content is specifically
              made for redistribution).
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>
              Hyperlinking to our Content
            </CustomHeading6>
            <CustomParagraph2 mode={mode}>
              The following organizations may link to our Web site without prior
              written approval:
              <br />
              <br />
              Government agencies;
              <br />
              Search engines;
              <br />
              News organizations;
              <br />
              Online directory distributors when they list us in the directory
              may link to our Web site in the same manner as they hyperlink to
              the Web sites of other listed businesses; and Systemwide
              Accredited Businesses except soliciting non-profit organizations,
              charity shopping malls, and charity fundraising groups which may
              not hyperlink to our Web site.
              <br />
              <br />
              These organizations may link to our home page, to publications or
              to other Web site information so long as the link: (a) is not in
              any way misleading; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products or
              services; and (c) fits within the context of the linking party's
              site.
              <br />
              <br />
              We may consider and approve in our sole discretion other link
              requests from the following types of organizations:
              <br />
              <br />
              commonly-known consumer and/or business information sources such
              as Chambers of Commerce, American Automobile Association, AARP and
              Consumers Union;
              <br />
              dot.com community sites;
              <br />
              associations or other groups representing charities, including
              charity giving sites,
              <br />
              online directory distributors;
              <br />
              internet portals;
              <br />
              accounting, law and consulting firms whose primary clients are
              businesses; and
              <br />
              educational institutions and trade associations.
              <br />
              <br />
              We will approve link requests from these organizations if we
              determine that: (a) the link would not reflect unfavorably on us
              or our accredited businesses (for example, trade associations or
              other organizations representing inherently suspect types of
              business, such as work-at-home opportunities, shall not be allowed
              to link); (b)the organization does not have an unsatisfactory
              record with us; (c) the benefit to us from the visibility
              associated with the hyperlink outweighs the absence of; and (d)
              where the link is in the context of general resource information
              or is otherwise consistent with editorial content in a newsletter
              or similar product furthering the mission of the organization.
              <br />
              <br />
              These organizations may link to our home page, to publications or
              to other Web site information so long as the link: (a) is not in
              any way misleading; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and it products or
              services; and (c) fits within the context of the linking party's
              site. If you are among the organizations listed in paragraph 2
              above and are interested in linking to our website,
              <br />
              <br />
              you must notify us by sending an e-mail to info@buckmint.org.
              Please include your name, your organization name, contact
              information (such as a phone number and/or e-mail address) as well
              as the URL of your site, a list of any URLs from which you intend
              to link to our Web site, and a list of the URL(s) on our site to
              which you would like to link. Allow 2-3 weeks for a response.
              <br />
              <br />
              Approved organizations may hyperlink to our Web site as follows:
              <br />
              <br />
              By use of our corporate name; or
              <br />
              By use of the uniform resource locator (Web address) being linked
              to; or
              <br />
              By use of any other description of our Web site or material being
              linked to that makes sense within the context and format of
              content on the linking party's site.
              <br />
              No use of Buckmint's logo or other artwork will be allowed for
              linking absent a trademark license agreement.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>Reservation of Rights</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              We reserve the right at any time and in its sole discretion to
              request that you remove all links or any particular link to our
              Web site. You agree to immediately remove all links to our Web
              site upon such request. We also reserve the right to amend these
              terms and conditions and its linking policy at any time. By
              continuing to link to our Web site, you agree to be bound to and
              abide by these linking terms and conditions.
            </CustomParagraph2>
          </TextDiv>

          <TextDiv>
            <CustomHeading6 mode={mode}>
              Removal of links from our website
            </CustomHeading6>
            <CustomParagraph2 mode={mode}>
              If you find any link on our Web site or any linked web site
              objectionable for any reason, you may contact us about this. We
              will consider requests to remove links but will have no obligation
              to do so or to respond directly to you.
              <br />
              <br />
              Whilst we endeavour to ensure that the information on this website
              is correct, we do not warrant its completeness or accuracy; nor do
              we commit to ensuring that the website remains available or that
              the material on the website is kept up to date.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>Content Liability</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              We shall have no responsibility or liability for any content
              appearing on your Web site. You agree to indemnify and defend us
              against all claims arising out of or based upon your Website. No
              link(s) may appear on any page on your Web site or within any
              context containing content or materials that may be interpreted as
              libelous, obscene or criminal, or which infringes, otherwise
              violates, or advocates the infringement or other violation of, any
              third party rights.
            </CustomParagraph2>
          </TextDiv>
          <TextDiv>
            <CustomHeading6 mode={mode}>Disclaimer</CustomHeading6>
            <CustomParagraph2 mode={mode}>
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website (including, without limitation, any
              warranties implied by law in respect of satisfactory quality,
              fitness for purpose and/or the use of reasonable care and skill).
              Nothing in this disclaimer will: limit or exclude our or your
              liability for death or personal injury resulting from negligence;
              limit or exclude our or your liability for fraud or
            </CustomParagraph2>
          </TextDiv>
        </ContentBox>
      </ContentDiv>

      <CommunityBox mode={mode}>
        <CommunityCard />
      </CommunityBox>
      <BelowFooterDiv>
        <FooterDiv>
          <FooterCard />
        </FooterDiv>
      </BelowFooterDiv>
    </MainDiv>
  );
};

export default TermsandCondition;
