import React from "react";
import { Helmet } from "react-helmet-async";

const Breadcrumbs = () => {
  const jsonLdBreadcrumb = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://buckmint.org/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "About",
        item: "https://buckmint.org/about",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "Blog Page",
        item: "https://buckmint.org/blog/page/1",
      },
      {
        "@type": "ListItem",
        position: 4,
        name: "Detail Blog",
        item: "https://buckmint.org/detail/{permalink}",
      },
      {
        "@type": "ListItem",
        position: 5,
        name: "FAQs",
        item: "https://buckmint.org/faqs",
      },
      {
        "@type": "ListItem",
        position: 6,
        name: "Policy",
        item: "https://buckmint.org/privacypolicy",
      },
      {
        "@type": "ListItem",
        position: 7,
        name: "Terms and Conditions",
        item: "https://buckmint.org/termsandcondition",
      },
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jsonLdBreadcrumb)}
      </script>
    </Helmet>
  );
};

export default Breadcrumbs;
